import React, { useState, useEffect } from 'react';
import './App.scss';
import { getMaildataList, getParterList, deleteToken } from './js/api-service';
import Header from './components/Header';
import Error from './components/Error';
import Footer from './components/Footer';
import Fail from './components/Fail';
import { useLocation, useNavigate } from 'react-router-dom';
import { InfoContext } from './components/context';
import LogoutSpinner from './components/LogoutSpinner';
import { Outlet } from 'react-router-dom';

export default function App() {

  const [session, setSession] = useState(null);
  const [name, setName] = useState(null);
  const [isBankID, setIsBankID] = useState(false);
  const [personNumber, setPersonNumber] = useState();
  const [data, setData] = useState(null);
  const [mailData, setMailData] = useState(null);
  const [parterList, setParterList] = useState(null);
  const [fail, setFail] = useState(false);
  const [error, setError] = useState(false);
  const [logOutSpinner, setLogOutSpinner] = useState(false);
  const [smsConfirm, setSmsConfirm] = useState(false);
  const [formFiles, setformFiles] = useState();
  const [bankIdFormData, setBankIdFormData] = useState();
  const [bankIdFiles, setBankIdFiles] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const localSession = sessionStorage.getItem("crawford");

  useEffect(() => {
    if (location.pathname.toLocaleLowerCase().startsWith("/error")) {
      setError(true);
    }
    if (location?.state?.session) {
      setSession(location.state.session);
    }
    fetchList();
  }, [location.state])

  const updateSessionStorage = () => {
    sessionStorage?.removeItem("crawford");
    const sessionData = new Date().getTime();
    sessionStorage.setItem("crawford", sessionData);
  }

  const fetchList = async () => {
    const urlParams = new URLSearchParams(location.search);
    let urlSession = session ?? urlParams.get('Session');
    if (urlSession === "error") {
      setError(true);
    }
    else if (urlSession && localSession) {
      urlSession = urlSession.replaceAll("\"", "");
      if (!session) {
        setSession(urlSession);
      }
      try {
        const parterResult = await getParterList(urlSession);
        setParterList(parterResult);
        const mailDataResult = await getMaildataList(urlSession);
        if (mailDataResult.message.toLowerCase() === "invalid session") {
          setFail(true);
        } else {
          if (mailDataResult.name.includes("--")) {
            setName(mailDataResult.name.split("--")[0]);
            setIsBankID(true);
            setPersonNumber(mailDataResult.personNumber)
          } else {
            setName(mailDataResult.name);
            setIsBankID(false);
          }
          let maildata = mailDataResult.data.map(res => res.maildata);
          setData(mailDataResult.data);
          setMailData(maildata);
        }
      } catch (error) {
        setFail(true);
      }
    } else {
      navigate("/");
    }
  }
  const updateFail = (value) => {
    setFail(value);
  }
  const logout = () => {
    setLogOutSpinner(true);
    setSmsConfirm(false);
    handleLogout(true);
  }
  const handleLogout = async (value) => {
    try {
      const deleteMessage = await deleteToken(session);
      if (deleteMessage.toString() === "deleted") {
        setLogOutSpinner(false);
        setSession(null);
        setSmsConfirm(false);
        navigate("/");
      }
    } catch (error) {
      setFail(true)
    }
  }

  const updateFormData = (name, value) => {
    let data = { ...bankIdFormData, [name]: value };
    setBankIdFormData(data);
  }

  const updateFormFiles = (files, isBankIdFiles = false) => {
    if (isBankIdFiles) {
      setBankIdFiles(files);
      return;
    }
    setformFiles(files);
  }

  return (
    <InfoContext.Provider value={{ session, name, isBankID, personNumber, data, mailData, fail, updateFail, logout, smsConfirm, setSmsConfirm, parterList, updateFormFiles, bankIdFormData, updateFormData, formFiles, bankIdFiles, updateSessionStorage }}>
      <Header />
      <main className="container">
        {logOutSpinner && <LogoutSpinner />}
        {fail && <Fail />}
        {error && !fail && <Error />}
        {!error && !fail &&
          <Outlet />
        }
      </main>
      <Footer />
    </InfoContext.Provider>
  );
}

